import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import "../Style/CardPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const EnglishCards2 = ({data}) => (
 <div> 

    <SEO 
    title="English Words Cards"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>


        <div className="CardsPageSettings">

        <div className="CardsPageMenu">
            <Link to="/english-cards-123456789"><div className="CardsPageMenuOptions">1</div></Link>
            <Link to="/english-cards-123456789-2"><div className="CardsPageMenuOptions">2</div></Link>
            <Link to="/english-cards-123456789-y"><div className="CardsPageMenuOptions">y</div></Link>
        </div>


                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Sentence</div>
                            <div className="PageCardBigTitle">I took a rag to wipe the stain from his forehead</div>

                                <div className="PageCardsGroup">


                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Rag</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> a piece of old cloth, especially one torn from a larger piece, used typically for cleaning things.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> he wiped his hands on an oily rag</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Stain</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> mark or discolour with something that is not easily removed.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> her clothing was stained with blood</div>

                                    </div>

                            

                                </div>


                </div>

                <hr />

                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Sentence</div>
                            <div className="PageCardBigTitle">Inadvertently and abruptly, I've tried to learn how to gut the fish and sew</div>

                                <div className="PageCardsGroup">


                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Gut It</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> remove the intestines and other internal organs from (a fish or other animal) before cooking it.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> How to gut a fish</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Sew</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> join, fasten, or repair (something) by making stitches with a needle and thread or a sewing machine.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> How to learn to sew?</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Inadvertently</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> without intention; accidentally.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> his name had been inadvertently omitted from the list</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Abruptly</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> suddenly and unexpectedly.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> the film ends rather abruptly</div>

                                    </div>

                            

                                </div>


                </div>

                <hr />

                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Sentence</div>
                            <div className="PageCardBigTitle">It was too irresistible and vexing at the same time</div>

                                <div className="PageCardsGroup">


                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Irresistible</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> too attractive and tempting to be resisted.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> "he found the delicious-looking cakes irresistible"</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Vexing</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> causing annoyance, frustration, or worry.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> Developing this new technology is truly vexing</div>

                                    </div>

                            

                                </div>


                </div>







        </div>

    </div>
)

export default EnglishCards2